<template>
  <div id="event_info" class="event_public">
    <EventHeader :eventHeaderItems="eventHeaderItems" :key="componentKey" />

    <PageSubHeader :pageSubHeader="pageSubHeader" :urlQuery="urlQuery" class="annual" />

    <div class="information">
      <div class="title w1100 mg-b-50">
        <h3 class="txt-bold">會員報名</h3>
        <img src="@/statics/img/index/icon_title2.svg" alt="">
      </div>
      <div class="w1000">
        <section id="form_detail">
          <div class="payment_member">
            <h4 class="payment_member_name txt-bold mg-tb-10">{{ user.realname }}</h4>
            <p class="payment_member_profession">(身分：<span id="identity">{{ roleName }}</span>)</p>
          </div>

          <!-- table area -->
          <div class="table_box">
            <table class="txt-center">
              <thead>
                <tr>
                  <th class="td-main txt-left"><p>{{ tableHead1 }}</p></th>
                  <th class="min100"><p>{{ tableHead2 }}</p></th>
                  <th class="min130"><p>{{ tableHead3 }}</p></th>
                </tr>
              </thead>
              <tbody id="table_data">

                <tr>
                  <td :data-title="tableHead1" class="td-main txt-left">
                    <p>其他 <template v-if="eventInfo.name">({{ eventInfo.name }})</template></p>
                  </td>
                  <td :data-title="tableHead2"><p>{{ eventPrice }}</p></td>
                  <td :data-title="tableHead3"></td>
                </tr>

              </tbody>
            </table>
          </div>

          <div class="payment_check txt-right">
            <p class="payment_check_total mg-tb-10">
              總金額 <span class="h3 txt-light_green">{{ eventPrice }}</span> 元
            </p>
              <form
                id="form_payment"
                @submit.prevent
              >
                <div class="payment_check_container mg-b-10">
                  <Select
                    id="pay_type"
                    v-model="paymentType"
                    :item="inputFormat.paymentSelect"
                    :rules="[
                      val => !!val || '必選欄位！',
                    ]"
                  />
                </div>

                <!-- 非會員醫師生身分顯示下方連結 -->
                <template v-if="parseInt(user.level, 10) === 3">
                  <router-link to="/application/index" class="click_to_apply h4 txt-bold mg-b-50"
                  >本會會員免報名費，點擊前往申請</router-link>
                </template>

                <div class="form_button">
                  <button type="button" class="btn btn-gray" @click="backToEdit">
                    修改報名表
                  </button>
                  <!-- <router-link
                    :to="`/register/event/member?event_id=${eventID}`"
                    class="btn btn-gray"
                  >
                    修改報名表
                  </router-link> -->
                  <button type="button" class="btn p" @click="onSubmit">
                    前往繳費
                  </button>
                </div>
              </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import EventHeader from '@/components/EventHeader.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import Select from '@/components/form/Select.vue';
import { RegisterEventSubHeader } from '@/lib/const';
import { eventsView, userInfo } from '@/lib/http';
import { mapGetters } from 'vuex';

export default {
  name: 'RegisterEventPayment',
  data() {
    return {
      /**
       * Static Data
       */
      ...RegisterEventSubHeader,
      tableHead1: '費用名稱',
      tableHead2: '金額',
      tableHead3: '備註',
      componentKey: 0,
      eventID: '',
      inputFormat: {
        paymentSelect: {
          placeHolder: '請選擇繳費方式',
          options: [
            '信用卡',
            // 'ATM轉帳',
            // '超商代碼繳費',
          ],
        },
      },
      paymentType: '',
      /**
       * Axios Response Data
       */
      eventHeaderItems: {},
      eventInfo: {},
      user: {
        realname: '',
        level: '',
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.event_id) {
      this.eventID = this.$route.query.event_id;
      eventsView({ uuid: this.eventID }).then((result) => {
        const target = result.result.data;
        this.eventHeaderItems.name = target.name;
        this.eventHeaderItems.eventNo = target.no_number;
        this.eventHeaderItems.fraction_a = target.fraction_a;
        this.eventHeaderItems.fraction_b = target.fraction_b;

        this.eventInfo = target;

        /** Refresh EventHeader */
        this.componentKey += 1;
      });

      if (this.hasToken) {
        userInfo().then((result) => {
          // console.log(result);
          this.user.realname = result.realname;
          this.user.level = result.level;
        });
      }
    } else {
      this.assignUrlRedirect('/register/event');
      this.updateModalInfoContent('尚未選取任何學術活動，請返回總覽頁選擇');
      this.toggleModalInfo(true);
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
      'isAuthenticated',
    ]),
    urlQuery() {
      return {
        name: 'event_id',
        value: this.eventID,
      };
    },
    pageTitle() {
      if (this.isAuthenticated) {
        return '會員報名';
      }
      return '非會員報名';
    },
    roleName() {
      if (this.user && this.user.level) {
        if (parseInt(this.user.level, 10) === 4) {
          return '護理人員';
        }
      }
      return '醫師';
    },
    eventPrice() {
      let price = '';
      if (this.user && this.user.level && this.eventInfo && this.eventInfo.price) {
        price = this.eventInfo.price[parseInt(this.user.level, 10)];
      }
      return price;
    },
    payId() {
      if (this.paymentType === '信用卡') {
        return 1;
      }
      return null;
    },
  },
  methods: {
    backToEdit() {
      const userLevel = this.user.level;
      if (userLevel === 1 || userLevel === 2) {
        this.$router.push({ path: `/register/event/member?event_id=${this.eventID}` });
      } else {
        this.$router.push({ path: `/register/event/nonMember?event_id=${this.eventID}` });
      }
    },
    /**
     * 表單送出
     */
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        const eventApplyInfo = this.$cookies.get('eventApplyInfo');
        eventApplyInfo.pay_id = this.payId;
        eventApplyInfo.price = this.eventPrice;
        this.$cookies.set('eventApplyInfo', JSON.stringify(eventApplyInfo));
        this.$router.push({ path: `/register/event/confirm?event_id=${this.eventID}` });
      } else {
        this.updateModalInfoContent('必填欄位尚未完成！');
        this.toggleModalInfo(true);
      }
    },
  },
  components: {
    EventHeader,
    PageSubHeader,
    Select,
  },
};
</script>
